import {useMemo} from "react";
import {ITerraceTypes} from "../components/blocks/TerracedGridTheme/Terrace";

const terraceTypesThatShowViewMoreButton = [ITerraceTypes.MEDIA, ITerraceTypes.SHOP];

export function useShowMoreContentButton(terrace: ITerrace) {
    return useMemo(() => {
        if (terraceTypesThatShowViewMoreButton.includes(terrace.type)) {
            return true
        }
        return false;
    }, [])
}
