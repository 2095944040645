import {createRef, isValidElement, Children, Component, ReactNode, RefObject} from 'react';
import classNames from 'classnames';
import styles from './styles/CleverSlider.module.scss';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import FlatIcon from "../FlatIcon";
import Button from "../Button";
import CleverSlideContainer from "./CleverSlideContainer";
import SwipeEvents from "../../blocks/TerracedGridTheme/SwipeEvents";
import CleverSliderItem from "./CleverSliderItem";
import {StoreContext} from "../../../stores/StoreLoader";

interface CleverSliderProps {
    totalSlides: number;
    handleNext?: () => void;
    padding?: number;
    height: number | string;
    sliderClassName?: string;
    containerClassName?: string;
    childSlideClassName?: string;
    trackClassName?: string;
    scrollArrowClassName?: string;
    disableArrowItems: number;
    blockObj?: ILinksTerrace;
    children?: ReactNode;
}

interface CleverSliderState {
    currentIndex: number;
    allowSlideRight: boolean;
}

@observer
class CleverSlider extends Component<CleverSliderProps, CleverSliderState> {

    static propTypes = {
        totalSlides: PropTypes.number.isRequired,
        handleNext: PropTypes.func,
        padding: PropTypes.number,
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        sliderClassName: PropTypes.string,
        containerClassName: PropTypes.string,
        childSlideClassName: PropTypes.string,
        trackClassName: PropTypes.string,
        scrollArrowClassName: PropTypes.string,
        disableArrowItems: PropTypes.number,
        blockObj: PropTypes.object,
    };

    static defaultProps = {
        padding: 10,
        height: 400,
        disableArrowItems: 1,
    };




    constructor(props: CleverSliderProps) {
        super(props);

        this.state = {
            currentIndex: 0,
            allowSlideRight: true,
        };
        this.swipeEvents = new SwipeEvents(this.handleNext, this.handlePrev)
        this.trackRef = createRef();
    }
    static contextType = StoreContext;
    private swipeEvents: SwipeEvents;
    private trackRef: RefObject<HTMLDivElement>;
    private newChildren: any;

    goTo = i => {
        if (this.props.children && isValidElement(this.props.children[i])) {
            this.setState({
                currentIndex: i,
            })
        }
    };

    getMore = () => {
        if (this.props.handleNext) {
            this.props.handleNext();
        }
    };

    handleNext = () => {
        if (this.state.currentIndex !== this.newChildren.length - 1) {
            this.setState(prevState => {
                if (this.trackRef && this.trackRef.current) {
                    const trackWidth = (this.trackRef.current.firstChild as HTMLElement)?.clientWidth;
                    const trackTransformStyle: string | null | undefined = (this.trackRef.current.firstChild as HTMLElement)?.style.transform;

                    let trackTranslate = 0;

                    if (trackTransformStyle) {
                        const matchResult = trackTransformStyle.match(/\d+/);
                        if (matchResult && matchResult.length > 0) {
                            trackTranslate = parseInt(matchResult[0], 10);
                        }
                    }

                    const trackRight = trackWidth - trackTranslate;
                    if (trackRight > 0 && trackRight < this.context.interfaceStore.dimensions.width * 2) {
                        this.getMore(); // get more when nearing end
                    }
                }

                return {
                    currentIndex: prevState.currentIndex + 1,
                }

            })
        }
    };

    handlePrev = () => {
        if (this.state.currentIndex !== 0) {
            this.setState(prevState => {
                return {
                    currentIndex: prevState.currentIndex - 1,
                }
            })
        }
    };

    setAllowSlideRight = (bool) => {
        this.setState({ allowSlideRight: bool });
    }

    render() {
        const containerClassName = classNames({
            [styles.container]: true,
            [this.props.containerClassName as string]: this.props.containerClassName,
        });
        const sliderContainerClassName = classNames({
            [styles.sliderContainer]: true,
            [styles.sliderContainerShowGradientRight]: this.state.allowSlideRight && this.state.currentIndex === 0,
            [styles.sliderContainerShowGradientLeft]: this.state.currentIndex > 0 && !this.state.allowSlideRight,
            [styles.sliderContainerShowGradientBoth]: this.state.allowSlideRight && this.state.currentIndex > 0,
            [this.props.sliderClassName as string]: this.props.sliderClassName,
        });
        const scrollArrowPrevClassName = classNames({
            [styles.scrollArrow]: true,
            [styles.scrollArrowDisabled]: this.state.currentIndex === 0,
            [styles.scrollArrowPrev]: true,
            [this.props.scrollArrowClassName as string]: this.props.scrollArrowClassName,
        });

        const childSlideContainerClassName = classNames({
            [styles.childSlideContainer]: true,
            [this.props.childSlideClassName as string]: this.props.childSlideClassName,
        });

        const slideRefs: RefObject<HTMLDivElement>[] = [];
        // using this rather than cloneElement because you can't pass a className with cloneElement
        this.newChildren = Children.toArray(this.props.children).map((child, index) => {
            if (isValidElement(child)) {
                const slideRef = createRef<HTMLDivElement>();
                slideRefs.push(slideRef);
                return (
                    <CleverSliderItem
                        key={child.props.id || index}
                        className={childSlideContainerClassName}
                        style={{marginRight: this.props.padding}}
                        ref={slideRef}
                        blockObj={this.props.blockObj}
                    >
                        {child}
                    </CleverSliderItem>
                )
            }
            else {
                return null;
            }
        });


        const scrollArrowNextClassName = classNames({
            [styles.scrollArrow]: true,
            [styles.scrollArrowDisabled]: this.state.currentIndex === this.newChildren.length - this.props.disableArrowItems || this.newChildren.length === 0,
            [styles.scrollArrowNext]: true,
            [this.props.scrollArrowClassName as string]: this.props.scrollArrowClassName,
        });
        return (
            <div className={containerClassName}>
                <CleverSlideContainer
                    className={sliderContainerClassName}
                    trackClass={this.props.trackClassName}
                    currentIndex={this.state.currentIndex}
                    setAllowSlideRight={this.setAllowSlideRight}
                    allowSlideRight={this.state.allowSlideRight}
                    ref={this.trackRef}
                    padding={this.props.padding}
                    getMore={this.getMore}
                    style={{height: this.props.height}}
                    slideMap={slideRefs}
                    events={this.swipeEvents.events}
                >
                    {this.newChildren}
                </CleverSlideContainer>

                {this.state.currentIndex > 0 &&
                    <Button aria-label={"Go To Previous Page"} type="button" className={scrollArrowPrevClassName}
                            onClick={this.handlePrev}
                            style={{transform: "rotate(180deg)"}}>
                        <FlatIcon name="flaticon-next"/>
                    </Button>}
                {this.state.allowSlideRight &&
                    <Button aria-label={"Go To Next Page"} type="button" className={scrollArrowNextClassName}
                            onClick={this.handleNext}>
                        <FlatIcon name="flaticon-next"/>
                    </Button>}
            </div>
        )
    }
}

export default CleverSlider
